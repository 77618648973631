import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Tooltip,
  AutoComplete,
  Select,
  Checkbox,
  InputNumber,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import UsersStore from '../../../../stores/users/usersStore';
import BenchStore from '../../../../stores/bench/benchStore';
import translate from '../../../../i18n/translate';
import '@reach/combobox/styles.css';
import Map from '../../Map/map.component';
import BenchFormFooter from '../../Bench/BenchForm/BenchFormFooter/bench-form-footer.component';
import './bench-form.style.scss';
import { addLeadingZeroes, isEmpty } from "../../../../utils/functions";
import { ERRORS } from "../../../../constants/errors";
import { useIntl } from "react-intl";

const { Option } = Select;

const BenchForm = ({ closeModalHandler, bench = null }) => {
  const [branches, setBranches] = useState([]);
  const [branchId, setBranchId] = useState('');
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pan, setPan] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [form] = Form.useForm();
  const [owner, setOwner] = useState('');
  const { formatMessage } = useIntl();

  const initialValues = {
    benchId: bench ? addLeadingZeroes(bench?.givenId, 3) : '',
    type: bench ? bench.type : '',
    owner: '',
    location: bench ? bench.location?.address : '',
    air: bench ? bench.sensorConfig?.bme280 : true,
    gps: bench ? bench.sensorConfig?.gps : true,
    coLevel: bench ? bench.sensorConfig?.mq7 : true,
    wifimonitor: bench ? bench.sensorConfig?.wifimonitor : true,
    cpu: bench ? bench.sensorConfig?.cpu : true,
    noise: bench ? bench.sensorConfig?.ky037 : true,
    uv: bench ? bench.sensorConfig?.veml6070 : true,
    acs712: bench ? bench.sensorConfig?.acs712 : true,
    ina219Solar: bench ? bench.sensorConfig?.ina219Solar : true,
    ina219Battery: bench ? bench.sensorConfig?.ina219Battery : true,
    ina219USB: bench ? bench.sensorConfig?.ina219USB : true,
    ina219Wireless: bench ? bench.sensorConfig?.ina219Wireless : true,
    temperatureOffset: bench ? bench.temperatureOffset : null,
    usbChargingOffset: bench ? bench.usbChargingOffset : null,
    wiFiChargingOffset: bench ? bench.wiFiChargingOffset : null,
    eChargingOffset: bench ? bench.eChargingOffset : null,
    noiseOffset: bench ? bench.noiseOffset : null,
    uvOffset: bench ? bench.uvOffset : null,
  };

  const isEditMode = bench && !isEmpty(bench);

  const getSelectedLanguage = async () => {
    const selected = await localStorage.getItem('language');
    setSelectedLanguage(selected);
  };

  useEffect(() => {
    UsersStore.loadBranches().then(() => {
      const branches = UsersStore.branches || [];
      setBranches(branches);

      const initialBranch = branches.find(branch => branch.id === bench?.branchId);
      const branchName = initialBranch ? initialBranch.name : '';
      setOwner(branchName);
      form.setFields([
        { name: 'owner', value: branchName }
      ]);
    });
  }, [bench]);

  useEffect(() => {
    getSelectedLanguage();
  }, [localStorage.language]);

  useEffect(() => {
    setBranchId(bench?.branchId ? bench.branchId : '');
  }, [bench]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 44.7826623, lng: () => 17.1989874 },
      radius: 100 * 1000,
    },
  });

  useEffect(() => {
    setValue(bench?.location?.address || '', false);
    setLat(bench?.location?.latitude);
    setLng(bench?.location?.longitude);
    setPan(true);
    clearSuggestions();
  }, [bench]);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setLat(lat);
      setLng(lng);
      setPan(true);
    } catch (error) {
      console.log('😱 Error: ', error);
    }
  };

  const submitForm = async (values) => {
    setLoading(true);
    const data = {
      givenId: parseInt(values.benchId),
      city: '',
      state: '',
      type: values.type,
      location: {
        longitude: lng,
        latitude: lat,
        address: value,
      },
      branch: { id: branchId },
      sensorConfig: {
        bme280: values.air || false,
        bmp280: false,
        gps: values.gps || false,
        mq7: values.coLevel || false,
        ina3221: false,
        wifimonitor: values.wifimonitor || false,
        cpu: values.cpu || false,
        acs712: values.acs712 || false,
        ina219Solar: values.ina219Solar || false,
        ina219Battery: values.ina219Battery || false,
        ina219USB: values.ina219USB || false,
        ina219Wireless: values.ina219Wireless || false,
        ky037: values.noise || false,
        veml6070: values.uv || false,
      },
      sensorOffsetConfig: {
        temperatureOffset: values.temperatureOffset || 0,
        usbChargingOffset: values.usbChargingOffset || 0,
        wiFiChargingOffset: values.wiFiChargingOffset || 0,
        eChargingOffset: values.eChargingOffset || 0,
        noiseOffset: values.noiseOffset || 0,
        uvOffset: values.uvOffset || 0,
      },
    };

    try {
      // create or update existing bench depending on the action
      if(!isEditMode) {
        await BenchStore.addBench(data);
      } else {
        await BenchStore.updateBench(bench.id, data);
      }
    } catch (err) {
      if(err === ERRORS.benchGivenIdNotUnique) {
        form.setFields([
          {
            name: 'benchId',
            errors: [`${formatMessage({ id: ERRORS.benchGivenIdNotUnique })}.`]
          }
        ]);
      }
      setLoading(false);
      return;
    }

    await BenchStore.loadBenches();
    form.resetFields();
    setValue('');
    clearSuggestions();
    setLoading(false);
    setPan(false);

    // refresh the bench screen with new data
    if(isEditMode) {
      await BenchStore.getBench(bench.id);
    }
    closeModalHandler();
  };

  return (
    <Form
      name="bench-form"
      className="bench-form"
      form={form}
      onFinish={submitForm}
      initialValues={initialValues}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <Input.Group>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col
                className="gutter-row"
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={24}
              >
                <Form.Item
                  name="benchId"
                  shouldUpdate
                  label={
                    <div className="bench-form__bench-id-label">
                      {translate('Bench ID')}&nbsp;
                      <Tooltip
                        color="#005450"
                        title={
                          selectedLanguage === 'english'
                            ? 'ID length must be 4'
                            : 'ID mora biti 4 karaktera'
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      validator: (_, value) => {
                        if(!value || value.length < 3) {
                          return Promise.reject(formatMessage({ id: 'Bench ID must be atleast 3 characters' }));
                        }
                        if(value && parseInt(value) < 0) {
                          return Promise.reject(formatMessage({ id: `Bench ID can't be negative` }));
                        }
                        return Promise.resolve();
                      }
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={0}
                    placeholder={
                      selectedLanguage === 'english'
                        ? 'Enter Bench ID'
                        : 'Unesite ID klupe'
                    }
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={24}
              >
                <Form.Item
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: translate('Please select type!'),
                    },
                  ]}
                  label={translate('Type')}
                >
                  <Select
                    placeholder={
                      selectedLanguage === 'english' ? 'Type' : 'Tip'
                    }
                    className="clients-form__type-select"
                  >
                    <Option value="wood">{translate('Wood')}</Option>
                    <Option value="solar">{translate('Solar')}</Option>
                    <Option value="wheel">{translate('Wheel')}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={translate('Owner')}
                name="owner"
                rules={[
                  {
                    required: true,
                    message: translate('Please select owner!'),
                  },
                ]}
                shouldUpdate
              >
                <AutoComplete
                  style={{ width: 200 }}
                  allowClear
                  notFoundContent={translate(
                    'There is no branch with this name.'
                  )}
                  placeholder={
                    selectedLanguage === 'english'
                      ? 'Start typing branch name'
                      : 'Počnite kucati ime poslovnice'
                  }
                  filterOption={(inputValue, option) => {
                    return option.value
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) !== -1
                  }}
                  value={owner}
                  onChange={value => setOwner(value)}
                  onSelect={(value, option) => setBranchId(option.key)}
                  onClear={() => setBranchId('')}
                >
                  {branches.map((branch) => (
                    <AutoComplete.Option value={branch.name} key={branch.id}>
                      {branch.name}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="location"
                label={
                  <span
                    className={
                      new Date().getHours() >= 20 || new Date().getHours() < 5
                        ? 'bench-form__location-input-label-night'
                        : 'bench-form__location-input-label-day'
                    }
                  >
                    {translate('Location')}
                  </span>
                }
                className="bench-form__location-search"
                shouldUpdate
                rules={[
                  {
                    required: false,
                    message: translate('Please select location!'),
                  },
                ]}
              >
                <Combobox onSelect={handleSelect}>
                  <ComboboxInput
                    value={value}
                    onChange={handleInput}
                    disabled={!ready}
                    placeholder={
                      selectedLanguage === 'english'
                        ? 'Enter location here'
                        : 'Unesite lokaciju'
                    }
                  />
                  <ComboboxPopover portal={false}>
                    <ComboboxList>
                      {status === 'OK' &&
                        data.map(({ id, description }) => {
                          return (
                            <ComboboxOption
                              key={description}
                              value={description}
                            />
                          );
                        })}
                    </ComboboxList>
                  </ComboboxPopover>
                </Combobox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item>
                <div className="bench-form__map">
                  <Map height="200px" pan={pan} lat={lat} lng={lng} />
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col className="gutter-row" xl={7} lg={7} md={24} sm={24} xs={24}>
          <h2 className="bench-form__sensors-title">{translate('Sensors')}</h2>
          <Row>
            <Col style={{ height: '40px' }} className="gutter-row" span={24}>
              <Form.Item name="air" valuePropName="checked">
                <Checkbox
                  className="reports-form__radio-button"
                  defaultChecked={true}
                >
                  {translate('Air (temperature, pressure, humidity)')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ height: '40px' }} className="gutter-row" span={24}>
              <Form.Item name="gps" valuePropName="checked">
                <Checkbox
                  className="reports-form__radio-button"
                  defaultChecked={true}
                >
                  {translate('GPS (bench location)')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ height: '40px' }} className="gutter-row" span={24}>
              <Form.Item name="coLevel" valuePropName="checked">
                <Checkbox
                  className="reports-form__radio-button"
                  defaultChecked={true}
                >
                  {translate('CO (Carbon Monoxide)')}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col style={{ height: '40px' }} className="gutter-row" span={24}>
              <Form.Item name="wifimonitor" valuePropName="checked">
                <Checkbox
                  className="reports-form__radio-button"
                  defaultChecked={true}
                >
                  {translate('WiFi monitor')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ height: '40px' }} className="gutter-row" span={24}>
              <Form.Item name="cpu" valuePropName="checked">
                <Checkbox
                  className="reports-form__radio-button"
                  defaultChecked={true}
                >
                  {translate('CPU Temperature')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ height: '40px' }} className="gutter-row" span={24}>
              <Form.Item name="acs712" valuePropName="checked">
                <Checkbox
                  className="reports-form__radio-button"
                  defaultChecked={true}
                >
                  {translate('Schuko socket')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ height: '40px' }} className="gutter-row" span={24}>
              <Form.Item name="ina219Solar" valuePropName="checked">
                <Checkbox
                  className="reports-form__radio-button"
                  defaultChecked={true}
                >
                  {translate('E-charging (Solar)')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ height: '40px' }} className="gutter-row" span={24}>
              <Form.Item name="ina219Battery" valuePropName="checked">
                <Checkbox
                  className="reports-form__radio-button"
                  defaultChecked={true}
                >
                  {translate('E-charging (Battery)')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ height: '40px' }} className="gutter-row" span={24}>
              <Form.Item name="ina219USB" valuePropName="checked">
                <Checkbox
                  className="reports-form__radio-button"
                  defaultChecked={true}
                >
                  {translate('E-charging (USB)')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ height: '40px' }} className="gutter-row" span={24}>
              <Form.Item name="ina219Wireless" valuePropName="checked">
                <Checkbox
                  className="reports-form__radio-button"
                  defaultChecked={true}
                >
                  {translate('E-charging (Wireless)')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ height: '40px' }} className="gutter-row" span={24}>
              <Form.Item name="noise" valuePropName="checked">
                <Checkbox
                    className="reports-form__radio-button"
                    defaultChecked={true}
                >
                  {translate('Noise')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ height: '40px' }} className="gutter-row" span={24}>
              <Form.Item name="uv" valuePropName="checked">
                <Checkbox
                    className="reports-form__radio-button"
                    defaultChecked={true}
                >
                  {translate('UV')}
                </Checkbox>
              </Form.Item>
            </Col>

          </Row>
        </Col>
        <Col xl={7} lg={7} md={24} sm={24} xs={24}>
          <h2 className="bench-form__sensors-title">{translate('Offset')}</h2>

          <Row>
            <Form.Item
              name="temperatureOffset"
              label={translate('Temperature offset')}
            >
              <InputNumber type="number" className="offset_input" />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              name="wiFiChargingOffset"
              label={translate('WiFi Charging offset')}
            >
              <InputNumber type="number" className="offset_input" />
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              name="usbChargingOffset"
              label={translate('USB Charging offset')}
            >
              <InputNumber type="number" className="offset_input" />
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              name="eChargingOffset"
              label={translate('E Charging offset')}
            >
              <InputNumber type="number" className="offset_input" />
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
                name="noiseOffset"
                label={translate('Noise offset')}
            >
              <InputNumber type="number" className="offset_input" />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
                name="uvOffset"
                label={translate('UV offset')}
            >
              <InputNumber type="number" className="offset_input" />
            </Form.Item>
          </Row>

        </Col>
      </Row>
      <Form.Item>
        <div className="ant-modal-footer" style={{ padding: 0 }}>
          <BenchFormFooter
            resetFields={() => form.resetFields()}
            closeModalHandler={() => {
              form.resetFields();
              setValue('');
              setPan(false);
              clearSuggestions();
              closeModalHandler();
            }}
            loading={loading}
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default BenchForm;
